
<template>
    <div id="commercial" class = 'commercial-page'>
      <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('CommercialFranchise')" />
    <div class="container">
      <Index />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Jobs Page',
  components: {
    Index: defineAsyncComponent( () => import('@/components/CommercialFranchise/Index.vue') ),
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
  },
  }
  </script>
